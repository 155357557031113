import React, { Component } from "react";

import {
  DKLabel,
  DKButton,
  showAlert,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
} from "deskera-ui-library";

import Utility, { getCapitalized } from "../../utility/Utility";
import UserProductRoleCard from "./UserProductRoleCard";
import UserManager from "../../managers/UserManager";
import { PRODUCTS, USER_ROLE, USER_TYPE } from "../../constants/Enum";
import SubscriptionManager from "../../managers/SubscriptionManager";

/*
- onSave
- onCancel
*/

class AddUserPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName:
        this.props.user && this.props.user.firstName
          ? this.props.user.firstName
          : "",
      lastName:
        this.props.user && this.props.user.lastName
          ? this.props.user.lastName
          : "",
      emailId:
        this.props.user && this.props.user.email ? this.props.user.email : "",
      saveTapped: false,
      productRole: {},
      selectedUser: this.props.user,
      emailFieldDisabled: this.props.user !== null ? true : false,
    };
  }

  componentDidMount() {
    this.setUserProductRole();
  }
  render() {
    return (
      <div className=" transparent-background">
        <div className="popup-window" style={{ maxWidth: "600px" }}>
          {this.getHeader()}
          {this.getUserForm()}
          {this.getProductRoleCards()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getHeader() {
    return (
      <div className="row justify-content-between">
        <DKLabel text="Enter user details" className="fw-m fs-l" />
        <div>
          <div className="row">
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
            <DKButton
              title="Save"
              className="bg-blue ml-r text-white fw-m"
              onClick={this.saveTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onCancel();
  };
  saveTapped = () => {
    this.setState({ saveTapped: true });
    if (this.validateProductRole()) {
      if (
        this.state.firstName.trim() !== "" &&
        this.state.lastName.trim() !== "" &&
        Utility.isValidEmail(this.state.emailId)
      ) {
        this.props.onSave(
          this.props.user
            ? this.prepareDataToUpdate()
            : this.prepareDataToSave()
        );
      }
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getUserForm() {
    return (
      <div className="column mt-l parent-width">
        <div className="row parent-width" style={{ alignItems: "flex" }}>
          <DKInput
            name="First name"
            title="First name"
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={true}
            value={getCapitalized(this.state.firstName)}
            onChange={(value) => {
              this.setState({ firstName: value });
            }}
            type={INPUT_TYPE.TEXT}
            canValidate={this.state.saveTapped}
            invalid={
              this.state.saveTapped && this.state.firstName.trim() === ""
            }
          />
          <DKInput
            className="ml-m"
            name="Last name"
            title="Last name"
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={true}
            value={getCapitalized(this.state.lastName)}
            onChange={(value) => {
              this.setState({ lastName: value });
            }}
            type={INPUT_TYPE.TEXT}
            canValidate={this.state.saveTapped}
            invalid={this.state.saveTapped && this.state.lastName.trim() === ""}
          />
        </div>
        <DKInput
          className="mt-l mb-l"
          name="Email address"
          title="Email address"
          required={true}
          value={this.state.emailId}
          onChange={(value) => this.setState({ emailId: value })}
          invalid={
            this.state.saveTapped && !Utility.isValidEmail(this.state.emailId)
          }
          type={INPUT_TYPE.EMAIL}
          canValidate={this.state.saveTapped}
          disabled={this.props.user ? true : false}
          readOnly={this.state.emailFieldDisabled}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
        />
      </div>
    );
  }

  getProductRoleCards() {
    return (
      <div
        className="row align-items-stretch mt-m"
        style={{ flexWrap: "wrap" }}
      >
        <div className="row justify-content-between mb-l">
          <DKLabel text="Products" className="fw-m text-gray" />
          <DKLabel text="User role" className="fw-m text-gray" />
        </div>

        {Object.keys(this.state.productRole).map((data) => {
          let totalLengthOfManagePerm = 0;
          let totalLengthofReadPerm = 0;
          this.state.productRole[data].permissions.forEach((item) => {
            Object.keys(item).forEach((key) => {
              item[key].forEach((permission) => {
                if (
                  permission.accessLevel === "rwd" ||
                  permission.accessLevel === "rw"
                ) {
                  totalLengthOfManagePerm += 1;
                }

                if (permission.accessLevel === "r") {
                  totalLengthofReadPerm += 1;
                }
              });
            });
          });
          return (
            <UserProductRoleCard
              product={data}
              isProductAssigned={this.state.productRole[data].isProductAssigned}
              role={
                this.state.productRole[data].role
                  ? this.state.productRole[data].role.name !== undefined
                    ? this.state.productRole[data].role.name
                    : this.state.productRole[data].role.shortCode
                    ? this.state.productRole[data].role.shortCode.toString()
                    : null
                  : null
              }
              RoleArray={UserManager.getRolesBasedOnUserType(
                data,
                this.state.productRole[data].isGuestUser
              )}
              userTypesArray={UserManager.getUserTypeArr(data)}
              UserType={
                this.state.productRole[data].isGuestUser
                  ? USER_TYPE.GUEST
                  : USER_TYPE.PAID
              }
              onRoleSelect={(role) => this.onRoleSelect(data, role)}
              userToggle={(type) => this.userToggle(data, type)}
              productToggle={(product, isOn) => {
                this.productToggle(product, isOn);
              }}
              permissions={this.state.productRole[data].permissions}
              onCheck={this.onCheck}
              onMasterCheck={(per, isAllSelected) => {
                this.onMasterCheck(data, per, isAllSelected);
              }}
              selectedUser={this.state.selectedUser}
              totalLengthOfManagePerm={totalLengthOfManagePerm}
              totalLengthofReadPerm={totalLengthofReadPerm}
            />
          );
        })}
      </div>
    );
  }
  checkProduct(data) {
    if (SubscriptionManager.displayPlusProduct()) {
      let isCountryUSorIN = UserManager.checkIfTenantIsUSOrIN();

      if (data.appName.toLowerCase() === PRODUCTS.PEOPLE) {
        return PRODUCTS.PEOPLE_PLUS.toUpperCase();
      } else if (data.appName.toLowerCase() === PRODUCTS.ERP) {
        if (isCountryUSorIN) {
          return PRODUCTS.BOOKS_PLUS.toUpperCase();
        } else {
          return PRODUCTS.ERP.toUpperCase();
        }
      } else {
        return data.appName;
      }
    } else {
      return data.appName;
    }
  }
  onCheck = (data) => {
    let dataAppName = this.checkProduct(data);

    let oldPermissions = this.state.productRole[dataAppName].permissions;
    oldPermissions.forEach((item) => {
      Object.keys(item).forEach((key) => {
        item[key].forEach((permission) => {
          if (permission.uuid === data.uuid) {
            permission.isChecked = permission.isChecked ? false : true;
          } else {
            if (permission.groupName === data.groupName)
              permission.isChecked = false;
          }
        });
      });
    });
    let productRole = this.state.productRole[dataAppName];
    productRole.permissions = oldPermissions;
    this.setState({
      productRole: { ...this.state.productRole, [dataAppName]: productRole },
    });
  };
  onMasterCheck = (product, per, isAllSelected) => {
    let oldPermissions = this.state.productRole[product].permissions;
    oldPermissions.forEach((item) => {
      Object.keys(item).forEach((key) => {
        item[key].forEach((permission) => {
          if (per.includes(permission.accessLevel)) {
            if (!isAllSelected) {
              permission.isChecked = true;
            } else {
              permission.isChecked = false;
            }
          } else {
            permission.isChecked = false;
          }
        });
      });
    });

    let productRole = this.state.productRole[product];
    productRole.permissions = oldPermissions;
    this.setState({
      productRole: { ...this.state.productRole, [product]: productRole },
    });
  };
  onRoleSelect(product, role) {
    let productRole = this.state.productRole[product];
    productRole.role = role;
    productRole.permissions = UserManager.getRolesBasedOnUserType(
      product,
      role.guest
    ).find((item) => item.id === role.id).modules;

    if (Utility.isEmpty(this.props.user)) {
      productRole.permissions.forEach((permission) => {
        Object.keys(permission).forEach((per) => {
          permission[per].forEach((p) => {
            p.isChecked = p.isDefault;
          });
        });
      });
    }

    this.setState({
      productRole: { ...this.state.productRole, [product]: productRole },
    });
  }
  userToggle(product, type) {
    let productRole = this.state.productRole[product];
    productRole.isGuestUser = type === USER_TYPE.GUEST;
    productRole.role = null;

    this.setState({
      productRole: { ...this.state.productRole, [product]: productRole },
    });
  }
  productToggle(product, flag) {
    let productRole = this.state.productRole[product];
    productRole.isProductAssigned = flag;
    this.setState({
      productRole: { ...this.state.productRole, [product]: productRole },
    });
  }
  getAppRoleGroup() {
    let appRoleGroup = [];
    for (const product in this.state.productRole) {
      let uuids = [];

      if (this.state.productRole[product].isProductAssigned === true) {
        this.state.productRole[product].permissions.forEach((permission) => {
          return Object.keys(permission).map((key) => {
            return permission[key].forEach((item) => {
              if (item.isChecked) {
                uuids.push(item.uuid);
              }
            });
          });
        });

        appRoleGroup.push({
          roleGroup: !Utility.isEmpty(this.state.productRole[product].role)
            ? this.state.productRole[product].role.shortCode !== undefined
              ? this.state.productRole[product].role.shortCode
              : this.state.productRole[product].role.toString()
            : null,
          appName: this.getProduct(product),
          rgShortCode: !Utility.isEmpty(this.state.productRole[product].role)
            ? this.state.productRole[product].role.shortCode !== undefined
              ? this.state.productRole[product].role.shortCode
              : this.state.productRole[product].role.toString()
            : null,
          moduleUuids: this.state.productRole[product].permissions ? uuids : [],
        });
      }
    }
    return appRoleGroup;
  }
  validateProductRole() {
    if (
      this.getAppRoleGroup().some((data) => Utility.isEmpty(data.roleGroup))
    ) {
      showAlert("Select Role", "Please select role for assigned product");
      return false;
    }
    return true;
  }

  setUserProductRole() {
    let productRoleState = {};
    SubscriptionManager.getProductsToShow().forEach((key) => {
      if (key === PRODUCTS.BOOKS_PLUS) {
        key = PRODUCTS.ERP;
      }
      if (key === PRODUCTS.PEOPLE_PLUS) {
        key = PRODUCTS.PEOPLE;
      }

      key = key.toUpperCase();

      productRoleState[key] = this.prepareProductRoleObject(key);
    });

    let isCountryUSorIN = UserManager.checkIfTenantIsUSOrIN();

    if (SubscriptionManager.displayPlusProduct()) {
      if (isCountryUSorIN && productRoleState.ERP !== undefined) {
        productRoleState = this.renameObjKey({
          oldObj: productRoleState,
          oldKey: "ERP",
          newKey: "BOOKS_PLUS",
        });
      }
      if (productRoleState.CRM !== undefined) {
        productRoleState = this.renameObjKey({
          oldObj: productRoleState,
          oldKey: "CRM",
          newKey: "CRM_PLUS",
        });
      }
      if (productRoleState.PEOPLE !== undefined) {
        productRoleState = this.renameObjKey({
          oldObj: productRoleState,
          oldKey: "PEOPLE",
          newKey: "PEOPLE_PLUS",
        });
      }
    }
    this.setState({ productRole: productRoleState });
  }

  prepareProductRoleObject(product) {
    let selectedRole = null;
    let userRoleInfo;
    if (this.props.user) {
      userRoleInfo = this.props.user.permissions.find(
        (role) => role.appName === product
      );
      if (!Utility.isEmpty(userRoleInfo))
        selectedRole = userRoleInfo?.roleGroup
          ? userRoleInfo?.roleGroup
          : userRoleInfo;
    }
    let permissions = [];
    if (!Utility.isEmpty(selectedRole)) {
      permissions =
        UserManager.getRolesBasedOnProducts(product)?.find(
          (role) => role.shortCode === selectedRole.shortCode
        )?.modules ?? [];
      permissions?.forEach((per) => {
        Object.keys(per).forEach((item) => {
          per[item].forEach((perObj) => {
            if (userRoleInfo.moduleUuids !== undefined) {
              if (userRoleInfo.moduleUuids.includes(perObj.uuid)) {
                perObj.isChecked = true;
              }
            }
          });
        });
      });
    }
    return {
      isGuestUser: !Utility.isEmpty(selectedRole) ? selectedRole.guest : true,
      role: !Utility.isEmpty(selectedRole) ? selectedRole : {},
      product: product,
      isProductAssigned: !Utility.isEmpty(selectedRole) ? true : false,
      permissions: permissions,
    };
  }

  prepareDataToSave() {
    return {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailId: this.state.emailId,
      password: "admin123",
      appUserCreateRequestList: this.getAppRoleGroup(),
    };
  }
  prepareDataToUpdate() {
    let assignedProducts = [];
    let unAssignedProducts = [];
    let roleShotInfo = [];

    let userIdArray = [this.props.user.id];
    for (const product in this.state.productRole) {
      if (this.state.productRole[product].isProductAssigned === true) {
        let uuids = [];
        this.state.productRole[product].permissions.forEach((permission) => {
          return Object.keys(permission).map((key) => {
            return permission[key].forEach((item) => {
              if (item.isChecked) {
                uuids.push(item.uuid);
              }
            });
          });
        });
        let erpShortCodes = UserManager.getParsedMetaData()?.[0]?.roles.map(
          (itemRole) => {
            return itemRole?.shortCode;
          }
        );

        let isERPRole =
          this.state.productRole[product]?.product ===
            PRODUCTS.ERP.toUpperCase() &&
          erpShortCodes?.includes(
            this.state.productRole[product]?.role?.shortCode
          );

        assignedProducts.push({
          appName:
            product === PRODUCTS.BOOKS_PLUS.toUpperCase()
              ? isERPRole
                ? this.getProduct(PRODUCTS.BOOKS_PLUS.toUpperCase())
                : this.getProduct(PRODUCTS.MRP.toUpperCase())
              : this.getProduct(product.toUpperCase()),
          roleGroup:
            this.state.productRole[product].role.shortCode !== undefined
              ? this.state.productRole[product].role.shortCode
              : this.state.productRole[product].role,
          rgShortCode:
            this.state.productRole[product].role.shortCode !== undefined
              ? this.state.productRole[product].role.shortCode
              : this.state.productRole[product].role,
          moduleUuids: uuids,
          userIds: userIdArray,
        });
        roleShotInfo.push({
          appName: product,
          roleGroup: this.state.productRole[product].role.shortCode,
        });
      } else {
        if (
          this.props.user.roleShortInfo.some(
            (role) => role.appName === this.getProduct(product)
          )
        ) {
          unAssignedProducts.push(this.state.productRole[product].product);
          roleShotInfo.push({
            appName: this.getProduct(product),
            roleGroup:
              this.state.productRole[product].role.shortCode !== undefined
                ? this.state.productRole[product].role.shortCode
                : this.state.productRole[product].role,
            isDelete: 1,
          });
        }
      }
    }

    let selectedUserRoles = [];
    this.props.user.roleShortInfo.map((item) => {
      selectedUserRoles.push(getCapitalized(item.shortCode));
    });
    const isSelectedUserOwner =
      UserManager.setAllRoles(selectedUserRoles) === USER_ROLE.OWNER;

    let isAdminLoggedIn = false;
    if (!UserManager.isUserOwner()) {
      isAdminLoggedIn = UserManager.isUserAdmin();
    }

    const isSelectedUserAdmin =
      UserManager.setAllRoles(selectedUserRoles) === USER_ROLE.ADMIN;

    return {
      assignUserRoleRequest: !(
        isSelectedUserOwner ||
        (isSelectedUserAdmin && isAdminLoggedIn)
      )
        ? assignedProducts
        : assignedProducts.filter(
            (product) =>
              product.appName !== PRODUCTS.ERP.toUpperCase() &&
              product.appName !== PRODUCTS.CONSOLE.toUpperCase()
          ),
      unAssignUserRoleRequest: {
        apps: unAssignedProducts,
      },
      updateUserInfo: {
        id: this.props.user.id,
        user: {
          id: this.props.user.id,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          emailId: this.state.emailId,
          password: "admin123",
          appUserCreateRequestList: !(
            isSelectedUserOwner ||
            (isSelectedUserAdmin && isAdminLoggedIn)
          )
            ? roleShotInfo
            : roleShotInfo.filter(
                (product) =>
                  product.appName !== PRODUCTS.ERP.toUpperCase() &&
                  product.appName !== PRODUCTS.CONSOLE.toUpperCase()
              ),
        },
      },
    };
  }

  getProduct(product) {
    if (product === PRODUCTS.PEOPLE_PLUS.toUpperCase()) {
      product = PRODUCTS.PEOPLE.toUpperCase();
    }
    if (product === PRODUCTS.BOOKS_PLUS.toUpperCase()) {
      product = PRODUCTS.ERP.toUpperCase();
    }
    return product;
  }

  renameObjKey = ({ oldObj, oldKey, newKey }) => {
    const keys = Object.keys(oldObj);
    const newObj = keys.reduce((acc, val) => {
      if (val === oldKey) {
        acc[newKey] = oldObj[oldKey];
      } else {
        acc[val] = oldObj[val];
      }
      return acc;
    }, {});

    return newObj;
  };
}

export default AddUserPopup;
