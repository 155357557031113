import React from "react";

import {
  DKButton,
  DKLabel,
  DKInput,
  showAlert,
  DKIcon
} from "deskera-ui-library";

import {
  PASSWORD_OBJ,
  PASSWORD_SETTING,
  PASSWORD_ERROR,
} from "../../constants/Enum";
import IAM from "../../services/iam";
import AppManager from "../../managers/AppManager";

import ic_key from "../../assets/icons/ic_key.png";

class ChangePasswordPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPasswordState: this.getInitialPswdState(),
      newPasswordState: this.getInitialPswdState(),
      confirmPasswordState: this.getInitialPswdState(),
      customError: [],
      didSaveTapped: false,
    };
  }

  //===============================================================
  getInitialPswdState = () => {
    return {
      value: "",
      isVisible: false,
      type: "password",
      buttonText: PASSWORD_SETTING.SHOW,
      error: false,
    };
  };
  //===============================================================

  handleInputChange = (value, type) => {
    if (type === PASSWORD_OBJ.OLD_PASSWORD) {
      this.setState((prevState) => ({
        customError: [],
        oldPasswordState: {
          ...prevState.oldPasswordState,
          value: value,
        },
      }));
    } else if (type === PASSWORD_OBJ.NEW_PASSWORD) {
      this.setState((prevState) => ({
        customError: [],
        newPasswordState: {
          ...prevState.newPasswordState,
          value: value,
        },
      }));
    } else if (type === PASSWORD_OBJ.CONFIRM_NEW_PASSWORD) {
      this.setState((prevState) => ({
        customError: [],
        confirmPasswordState: {
          ...prevState.confirmPasswordState,
          value: value,
        },
      }));
    }
  };

  saveTapped = () => {
    this.setState({
      didSaveTapped: true,
    });
    const isValid = this.validateInputs();

    if (isValid) {
      IAM.changePassword({
        newPassword: this.state.newPasswordState.value,
        oldPassword: this.state.oldPasswordState.value,
      }).then((res) => {
        showAlert(
          "Password updated!",
          "Your new password has been updated successfully. Please login again.",
          [
            {
              title: "Ok",
              className: "bg-blue text-white fw-m",
              onClick: () => {
                this.logout();
              },
            },
          ]
        );
      },
      (err) => {}
      );
    }
  };

  logout = () => {
    IAM.logOut().then(
      (res) => {
        AppManager.gotoLoginPage(false);
      },
      (err) => {}
    );
  };

  validateInputs = () => {
    const {
      oldPasswordState,
      newPasswordState,
      confirmPasswordState,
    } = this.state;
    let errors = [];

    if (
      oldPasswordState.value.trim() === "" ||
      newPasswordState.value.trim() === "" ||
      confirmPasswordState.value.trim() === ""
    ) {
      errors.push(PASSWORD_ERROR.ERROR_BLANK);
    }

    if (newPasswordState.value.trim() !== confirmPasswordState.value.trim()) {
      errors.push(PASSWORD_ERROR.ERROR_NOT_MATCHED);
    }

    if (oldPasswordState.value.trim() === newPasswordState.value.trim()) {
      errors.push(PASSWORD_ERROR.ERROR_NOT_MATCHED_FIELD);
    }

    if (
      newPasswordState.value.length < 8 ||
      confirmPasswordState.value.length < 8
    ) {
      errors.push(PASSWORD_ERROR.ERROR_LENGTH);
    }

    if (
      newPasswordState.value.length > 72 ||
      confirmPasswordState.value.length > 72
    ) {
      errors.push(PASSWORD_ERROR.ERROR_LENGTH);
    }

    if (errors.length !== 0) {
      this.setState({
        customError: errors,
      });
    }

    return errors.length === 0;
  };

  render() {
    return (
      <div className=" transparent-background">
        <div className="popup-window" style={{ maxWidth: 400 }}>
          {this.getHeader()}
          {this.getInputView()}
        </div>
      </div>
    );
  }

  getHeader() {
    return (
      <div className="row justify-content-between">
        <div>
          <div className="row">
            <DKIcon src={ic_key} className=" ic-s-2 mr-s" />
            <DKLabel text="Change Password" className="fw-m fs-l" />
          </div>
        </div>
        <div>
          <div className="row">
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
            <DKButton
              title="Save"
              className="bg-blue ml-r text-white fw-m"
              onClick={this.saveTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  getInputView = () => {
    const {
      oldPasswordState,
      newPasswordState,
      confirmPasswordState,
      customError,
    } = this.state;

    return (
      <div className="parent-width text-align-left">
        {/* old password */}
        <div className="mt-xl">
          <DKLabel text="Enter old password" className="fw-m" />
          <div className="row">
            <DKInput
              needHeader={false}
              name="Old Password"
              placeholder="Old Password *"
              value={oldPasswordState.value}
              required
              type={oldPasswordState.type}
              onChange={(value) =>
                this.handleInputChange(value, PASSWORD_OBJ.OLD_PASSWORD)
              }
              canValidate={this.state.didSaveTapped}
              invalid={
                this.state.didSaveTapped && oldPasswordState.value.trim() === ""
              }
              className="mt-s"
            />
          </div>
        </div>

        {/* new password */}
        <div className="mt-xl">
          <DKLabel text="Enter new password" className="fw-m" />
          <div className="row">
            <DKInput
              needHeader={false}
              name="New Password"
              placeholder="New Password *"
              value={newPasswordState.value}
              required
              type={newPasswordState.type}
              onChange={(value) =>
                this.handleInputChange(value, PASSWORD_OBJ.NEW_PASSWORD)
              }
              canValidate={this.state.didSaveTapped}
              invalid={
                this.state.didSaveTapped &&
                (newPasswordState.value.trim() === "" ||
                  newPasswordState.value.length < 8)
              }
              className="mt-s"
              validator={(text) => {
                return text.length >= 8 && text.length <= 72
              }}
              errorMessage={this.checkPassword(this.state.newPasswordState.value)}
            />
          </div>
        </div>

        {/* confirm new password */}
        <div className="mt-xl">
          <DKLabel text="Confirm new password" className="fw-m" />
          <div className="row">
            <DKInput
              needHeader={false}
              name="Confirm New Password"
              placeholder="Confirm New Password *"
              value={confirmPasswordState.value}
              required
              type={confirmPasswordState.type}
              onChange={(value) =>
                this.handleInputChange(value, PASSWORD_OBJ.CONFIRM_NEW_PASSWORD)
              }
              canValidate={this.state.didSaveTapped}
              invalid={
                this.state.didSaveTapped &&
                (confirmPasswordState.value.trim() === "" ||
                  confirmPasswordState.value.length < 8)
              }
              className="mt-s mb-l"
              validator={(text) => {
                return text.length >= 8 && text.length <= 72
              }}
              errorMessage={this.checkPassword(this.state.confirmPasswordState.value)}
            />
          </div>
        </div>

        {customError.indexOf(PASSWORD_ERROR.ERROR_NOT_MATCHED) !== -1 ? (
          <DKLabel
            text="New password and Confirm new password should be same"
            className="text-red mt-r mb-m"
          />
        ) : null}

        {customError.indexOf(PASSWORD_ERROR.ERROR_BLANK) !== 0 && customError.indexOf(PASSWORD_ERROR.ERROR_NOT_MATCHED_FIELD) !== -1 ? (
          <DKLabel
            text="Old password and New password should not be same"
            className="text-red mt-r mb-m"
          />
        ) : null}
      </div>
    );
  };

  checkPassword(value) {
    if (value.length === 0){
      return "Please enter a valid password"
    } else {
      if (value.length <= 8){
        return "New password length cannot be less than 8 characters"
      } else if (value.length > 72) {
          return "New password length cannot be more than 72 characters"
      }
    }
  }

  cancelTapped = () => {
    this.props.onCancel();
  };
}

export default ChangePasswordPopup;
