export default class ApiConstants {
  static URL = {
    BASE: process.env.REACT_APP_BASE_URL,
    IAM: {
      REDIRECT: process.env.REACT_APP_LOGIN_URL,
      LOG_OUT: `iam/auth/logout2`,
      STATUS: `iam/auth/sign-in/login/status`,
      SWITCH_TENANT: `iam/users/web/switch-tenant`,
      TFA_STATUS: `iam/auth/sign-in/web/mfa-status`,
      ENABLE_TFA: `iam/auth/sign-in/web/mfa-enable`,
      DELETE_TFA: `iam/auth/sign-in/web/mfa`,
      DELETE_COMPANY: `iam/users/tenant/`,
      RESET_COMPANY: `iam/users/tenant-reset`,
      ADD_USER: `iam/users/tenants/module-roles`,
      UPDATE_USER: `users/update/info-role`,
      CHANGE_PASSWORD: `iam/users/change-password`,
      CODE_TFA: `iam/auth/sign-in/web/mfa`,
      RESEND_VERIFICATION_MAIL: `iam/auth/email/regenerate`,
      INTERCOM_HASH: `iam/users/intercom-hash`,
      CHANGE_EMAIL_OTP: `iam/users/emailchangeotp`,
      CONFIRM_EMAIL_OTP: `iam/users/emailchangeconfirmotp`,
      UPDATE_PROFILE: (iamUserId) => `iam/users/invite/${iamUserId}`,
      GET_PROFILE: (iamUserId) => `iam/users/${iamUserId}`,
      REFRESH_TOKEN: `iam/auth/web/getrefreshtoken`,
      IDLE_TIMEOUT_TOKEN_EXPIRY: `iam/token/idletimeouttokenexpiry`,
      SET_PASSWORD: `iam/users/adminSetPassword`,
      RESET_PASSWORD: `iam/users/reset-password/link`
    },
    SUBSCRIPTION: {
      GET_DETAILS: `subengine/subscription`,
      START_TRIAL: `subengine/subscription`,
      BOOKKEEPER_SUBSCRIPTION: `subengine/subscription/bookkeeper`,
      PLAN_DETAILS: `anonymous/subengine/plans`,
      GET_PAYMENT_METHOD: `subengine/billing/paymentmethod`,
      // GET__ADYEN_PAYMENT_METHOD: `subengine/billing/paymentmethod`,
      POST__ADYEN_PAYMENT_DETAILS: `subengine/billing/a/paymentmethod`,
      // GET__CASHFREE_PAYMENT_METHOD: `subengine/billing/paymentmethod`,
      POST__CASHFREE_REQUEST_PAGE: `subengine/billing/c/paymentmethod`,
      GET_FAILED_INVOICE: `subengine/failedinvoice`,
      GET_INVOICE: `subengine/invoice`,
      GET_PAID_INVOICE: `subengine/paidinvoice`,
      ADD_BILLING_ADDRESS: `/subengine/billing/address`,
      GET_BILLING_ADDRESS: `/subengine/billing/address`,
      GET_MY_PLAN: `subengine/myplans`,
      GET_PREPAID_CODE_DETAILS: `subengine/subscription/checkprepaid`,
      GET_DISCOUNT_CODE_DETAILS: `subengine/coupon/check`,
      APPLY_PREPAID_CODE: `/subengine/subscription?PrepaidCoupon=`,
      APPLY_DISCOUNT_CODE: `/subengine/coupon/redeem`,
      GET_PLAN_UPDATED_AMOUNT: `/subengine/subscription/updateamount`,
      CANCEL_SUBSCRIPTION: `/subengine/subscription/cancel`,
      GET_ADD_USER_AMOUNT: `subengine/subscription/adduseramount`,
      PRINT: `subengine/invoice/print`,
      LOCATION: `anonymous/location`,
      PHONE_NUMBER: `subengine/billing/phone`,
      PHONE_OTP: `subengine/billing/phoneotp`,
    },
    USER: {
      ROLES: `users/roles`,
      DELETE_REASON: `iam/users/account/`,
      DETAIL: `users/users/count`,
      USER_INFO: `users/userinfo`,
      USER_INFO_BY_ID: `users/userinfo?query=id=`,
      TENANTS: `users/tenants`,
      DELETE_USER: `users/tenants-roles`,
      GET_LOGGED_IN_USER_PERMISSIONS: `users/roles`,
      PEOPLE_USER_INFO: `people/users/login/info`,
    },
    TENANT: {
      GET_DETAILS: `tenants/details`,
      CLIENT_DETAILS: `tenants/bookkeeper/client-tenants`,
      COMPANY_NAME: `tenants/TENANT_ID/settings/name`,
      RESET_COMPANY: `tenants/reset`,
      DELETE_COMPANY: `tenants/`,
      PAYMENT: `tenants/bookkeeper/payment`,
      ORG_WITHOUT_SETUP: `tenants/**/organisationwithoutsetup`,
      TENANTS: `users/tenants`,
      GET_METADATA: `tenants/permissions/metadata`,
      GET_PERMISSIONS: `tenants/permissions/users`,
      // MODULE_ROLES: `iam/users/tenants/module-roles`
    },
    CRM: {
      USERS: `crm/users`,
      GUIDE: `crm/guide/helpcenter`,
      CRM_FORM: "crm/core/deskera/open/form/",
    },
    STRIPE: {
      GET_INTENT: `subengine/billing/setupintent`,
      SET_PAYMENT: `subengine/billing/paymentmethod`,
    },
    ROLE_GROUP: {
      GET: `rolegroups/get/roles`,
    },
    REPORTS: {
      AGE_RECEIVABLE: `report-engine/aged-dashboard`,
      INVOICES: `invoices/sales/document/summary`,
      COMPLIANCE_BASED_AGE_RECEIVABLE: `reports/aged/aged-dashboard`,
      COMPLIANCE_BASED_INVOICES: `reports/document/sales/summary`,
      BANK_ACCOUNTS: `account/bankaccounts`,
      WON_AND_LOST_DEALS: `crm/core/dashboard/deal-won-lost-count`,
      PIPELINES: `crm/deals/pipelines`,
      CONTACTS: `crm/contacts/dashboard/contact_report`,
      EXPENSES_CLAIMED: `expenses/trend`,
      PAYROLL: `people-pay-run/paystubs/year`,
      LEAVES_PER_MONTH: `eleave/applications/leave/count`,
      GET_BANK_ACCOUNT_REPORT: `account/reports/dashboard/bank`,
      EXPENSE_BREAKDOWN: "expenses/status",
      PAYSTUB_EMPLOYEE: (userId) =>
        `people-pay-run/paystubs/employee/${userId}`,
      ALL_LEAVES_PER_MONTH: `eleave/applications/leave/count/all`,
    },
    ACTIVE_CAMPAIGN: {
      EVENT_TRACKING: `ac/event`,
      CREATE_UPDATE_CONTACT: `ac/contact/sync`,
    },
    CRISP: {
      UUID: `tk/uuid`,
    },
    TABLE: {
      GET_ALL: `crm/core/table`,
      GET: (tableId) => `crm/core/table/${tableId}`,
      GET_RECORD: (tableId) => `crm/core/table/${tableId}/record`,
      GET_RECORD_BY_PAGE: (tableId) => `crm/core/table/${tableId}/paged-record`,
      GET_RECORD_BY_ID: (recordId, tableId) =>
        `crm/core/table/${tableId}/record/${recordId}`,
    },
    DASHBOARD: {
      GET_PREFERENCE: "crm/core/settings/user",
      SET_PREFERENCE: "crm/core/settings/user",
      DEAL_COUNT_BY_STAGE: `crm/core/dashboard/stage-deal-count`,
      DEAL_WON_LOST: `crm/core/dashboard/deal-won-lost-count`,
      RECORD_BY_TREND: (tableID) =>
        `crm/core/dashboard/table/${tableID}/trend-chart`,
      RECORD_COUNT_BY_COLUMN_ID: (tableID, columnID) =>
        `crm/core/dashboard/table/${tableID}/column/${columnID}/count`,
    },
    CREDIT: {
      CHECK_CODE: `subengine/credits/check`,
      APPLY: `subengine/credits/apply`,
      PRICING: `reports/metered-pricing`,
      BILLING_DETAILS : `subengine/credits/billing`
    }
  };

  static IS_CASHFREE_GLOBALLY_ENABLED =
    process.env.REACT_APP_IS_CASHFREE_GLOBALLY_ENABLED;

  static CARD_PAYMENT = {
    STRIPE: true,
    ADYEN: true,
  };

  static ACCESS_TOKEN = null;
  static LOCALHOST_URL = "localhost:3000";

  static PRODUCT_URL_GO = process.env.REACT_APP_URL_GO;
  static PRODUCT_URL_ERP = process.env.REACT_APP_URL_ERP;
  static PRODUCT_URL_ERP_PLUS = process.env.REACT_APP_URL_BOOKS_PLUS;
  static PRODUCT_URL_MRP = process.env.REACT_APP_URL_MRP;
  static PRODUCT_URL_CRM = process.env.REACT_APP_URL_CRM;
  static PRODUCT_URL_CRM_PLUS = process.env.REACT_APP_URL_CRM_PLUS;
  static PRODUCT_URL_PEOPLE = process.env.REACT_APP_URL_PEOPLE;
  static PRODUCT_URL_PEOPLE_PLUS = process.env.REACT_APP_URL_PEOPLE_PLUS;
  static PRODUCT_URL_WEBSITE_BUILDER =
    process.env.REACT_APP_URL_WEBSITE_BUILDER;
  static PRODUCT_URL_SHOP = process.env.REACT_APP_URL_SHOP;
  static PRODUCT_URL_REPORT_BUILDER = process.env.REACT_APP_URL_REPORT_BUILDER;
  static PRODUCT_URL_DOCUMENT_BUILDER =
    process.env.REACT_APP_URL_DOCUMENT_BUILDER;
  static PRODUCT_URL_WORKFLOW_ENGINE =
    process.env.REACT_APP_URL_WORKFLOW_ENGINE;
  static PRODUCT_URL_AI = process.env.REACT_APP_URL_AI;

  static URL_MOBILE_IOS =
    "https://apps.apple.com/app/desk-business-and-accounting/id1463523833?ls=1";
  static URL_MOBILE_ANDROID =
    "https://play.google.com/store/apps/details?id=com.deskera.desk";

  static TIMEOUT_ENABLED = eval(process.env.REACT_APP_TIMEOUT_ENABLED);
  static IDLE_TIMER_ID = process.env.REACT_APP_IDLE_TIMER_ID;
  static COOKIE_DOMAIN = process.env.REACT_APP_GTM_COOKIE_URL;
  static SESSION_TIMEOUT_OPTIONS = eval(process.env.REACT_APP_SESSION_TIMEOUT_OPTIONS);
  static IDLE_TIMEOUT_OPTIONS = eval(process.env.REACT_APP_IDLE_TIMEOUT_OPTIONS);
  static AUTO_LOGOUT_TIME = eval(process.env.REACT_APP_AUTO_LOGOUT_TIME);
}
