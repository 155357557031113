export const MAIN_HOLDER_ID = "root";
export const MAX_SECTION_WIDTH = 1200;
export const CHART_HEIGHT = 220;
export const CHART_WIDTH = 418;
export const MIN_SEARCH_CHAR = 3;
export const ZERO = 0;
export const REGEX = {
  WEBSITE_DOMAIN:
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
};
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const DAY_INTERVAlS = [
  "Current",
  "1-30 days",
  "31-60 days",
  "61-90 days",
  "Over 90 days",
];
export const VIEWPORT_SIZES = {
  EXTRA_SMALL: "xs",
  SMALL: "sm",
  LARGE: "lg",
};

export const BREAKPOINTS = {
  SMALL: 560,
  LARGE: 800,
};

export const MOBILE_APP_ACTIONS = {
  HOME: "HOME",
  SUBSCRIPTION_SUCCESS: "SUBSCRIPTION_SUCCESS",
};

export const WALKTHROUGH_STATUS = "walkthroughStatus";

export const CHART_INTERVALS = [
  {
    name: "Last Week",
    interval: "weekly",
  },
  {
    name: "Last Month",
    interval: "monthly",
  },
  {
    name: "Last Year",
    interval: "yearly",
  },
];

export const ONBOARD_DETAILS = {
  DESKERA: "Deskera",
  WELCOME: "Welcome!",
  SET_UP: "Lets Get You Set Up",
  PROFILE_INFO: "Enter your profile information",
  BUSINESS_INFO: "Tell us about your business",
  PROFILE_INFO_SETUP: "Enter your Profile information to set you up.",
  BUSINESS_INFO_SETUP:
    "Tell us about your business so that we can tailor your experience",
  GROW_BUSINESS: "Grow Your Business with Deskera",
};
export const CURRENT_ENV = process.env.REACT_APP_GATSBY_ENV;
export const CURRENT_ENV_FOR_CONTACT_SALES =
  process.env.REACT_APP_BASE_URL_WEBSITE;
export const CONTACT_US = "/contact-us";

export const LANGUAGES_CODE = {
  ENGLISH_US: "en",
  ENGLISH_UK: "en_GB",
  ENGLISH_IN: "en_IN",
  // ENGLISH_UK: "en_GB",
  // GERMAN: "ge",
  // SPANISH: "es",
  // HINDI: "hi",
  // TAMIL: "ta",
  // PUNJABI: "pb",
  // GUJARATI: "gj",
  // MARATHI: "mh",
  // BENGALI: "bg",
  // KANNADA: "ka",
  // MALAYALAM: "ma",
  // TELUGU: "te",
};
export const LANGUAGES = {
  ENGLISH_US: "English (US)",
  ENGLISH_UK: "English (UK)",
  ENGLISH_IN: "English (IN)",
  // BAHASA_INDONESIA: 'Bahasa Indonesia',
  // FRENCH: 'French',
  // GERMAN: 'German',
  // SPANISH: 'Spanish',
  // HINDI: 'Hindi',
  // TAMIL: 'Tamil',
  // PUNJABI: 'Pubnjabi',
  // GUJARATI: 'Gujarati',
  // MARATHI: 'Marathi',
  // BENGALI: 'Bengali',
  // KANNADA: 'Kannada',
  // MALAYALAM: 'Malayalam',
  // TELUGU: 'Telugu'
};
export const COUNTRY = {
  IN: "IN",
  US: "US",
  SG: "SG",
  MY: "MY",
};
export var pageUrls = {
  SIGN_UP: `/signup`,
  BOOKS: `/books`,
  MRP: `/mrp`,
  ERP: `/erp`,
  CONTACT_US: `/contact-us`,
};

export const PLAN_IDS = {
  SMALL_BUSINESS: "small business",
  GROWTH: "growth",
  MID_MARKET: "mid market",
  ENTERPRISE: "enterprise",
};

export const TALK_TO_SALES_URL_MRP =
  process.env.REACT_APP_BASE_URL_WEBSITE +
  "/contact-us?prefilltext= I want to know more about MRP - Mid Market plan";
